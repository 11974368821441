import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumbs from "../components/BreadCrumbs";
import MainTitle from "../components/MainTitle";
// import { Container, Row, Col } from "react-bootstrap";
import { API } from "../http/API";
import { Col, Container, Form, Row } from "react-bootstrap";
import ButtonTheme from "../components/ButtonTheme/ButtonTheme";
import "../sections/OrderTracking/OrderTracking.scss";
import truckImage from "../../src/assets/icons/0111.png";

import { Helmet } from "react-helmet";

import { landingPageProducts } from "../utils/data";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import "./Landing.scss";

import bannerLarge from "./../assets/images/pigeonLandingPage/Her_banner.webp";
import bannerSmall from "./../assets/images/pigeonLandingPage/Responsive Banner 02.jpg";
import bannerLargeAr from "./../assets/images/pigeonLandingPage/Her_banner Ar.jpg";
import bannerSmallAr from "./../assets/images/pigeonLandingPage/Responsive Banner AR.jpg";
import fifth_section__img from "./../assets/images/pigeonLandingPage/fifth_section__img.png";
import fifth_section_ar_img from "./../assets/images/pigeonLandingPage/fifth_section_ar_img.png";
import sixth_section_img from "./../assets/images/pigeonLandingPage/sixth_section_img.webp";
import anti_colic_system from "./../assets/images/pigeonLandingPage/ANTI-COLIC SYSTEM.webp";
import c1 from "./../assets/images/pigeonLandingPage/c1.png";
import c2 from "./../assets/images/pigeonLandingPage/c2.png";
import c3 from "./../assets/images/pigeonLandingPage/c3.png";
import element_03 from "./../assets/images/pigeonLandingPage/element_03.png";
import element_04 from "./../assets/images/pigeonLandingPage/element_04.png";
import element_04_ar from "./../assets/images/pigeonLandingPage/element_04_ar.png";
import latch_on_line from "./../assets/images/pigeonLandingPage/LATCH-ON LINE.webp";
import soft_and_flexible_nipple from "./../assets/images/pigeonLandingPage/SOFT & FLEXIBLE NIPPLE.webp";

// import softtouch_tm from "./../assets/images/pigeonLandingPage/SoftTouch-TM.png";
// import top_design_old from "./../assets/images/pigeonLandingPage/top_design_old.svg";
// import top_design from "./../assets/images/pigeonLandingPage/top_design.svg";
// import view_more_icons from "./../assets/images/pigeonLandingPage/View More Icons.webp";
// import bg_image from "./../assets/images/pigeonLandingPage/BG_image.png";
// import bg_shape_1 from "./../assets/images/pigeonLandingPage/BG_shape-1.png";
// import bg from "./../assets/images/pigeonLandingPage/BG.png";
// import bg1 from "./../assets/images/pigeonLandingPage/bg1.jpg";
// import bg2 from "./../assets/images/pigeonLandingPage/bg2.jpg";
// import bottom_design from "./../assets/images/pigeonLandingPage/bottom_design.jpeg";
// import favicon from "./../assets/images/pigeonLandingPage/favicon.png";
// import footer_wave_inner from "./../assets/images/pigeonLandingPage/footer-wave-inner.svg";
// import footer_wave from "./../assets/images/pigeonLandingPage/footer-wave.svg";
// import her_banner from "./../assets/images/pigeonLandingPage/Her_banner.webp";
// import logo from "./../assets/images/pigeonLandingPage/logo.svg";
// import logow from "./../assets/images/pigeonLandingPage/logow.svg";
// import n from "./../assets/images/pigeonLandingPage/n.png";
// import new_improved from "./../assets/images/pigeonLandingPage/New_Improved.png";
// import next from "./../assets/images/pigeonLandingPage/next.webp";
// import p1 from "./../assets/images/pigeonLandingPage/p1.png";
// import po1 from "./../assets/images/pigeonLandingPage/po1.jpg";
// import ppp from "./../assets/images/pigeonLandingPage/ppp.svg";
// import prev from "./../assets/images/pigeonLandingPage/prev.webp";
// import protect_the_earth from "./../assets/images/pigeonLandingPage/Protect The Earth.webp";
// import pw from "./../assets/images/pigeonLandingPage/pw.svg";
// import responsive_banner_02 from "./../assets/images/pigeonLandingPage/Responsive Banner 02.jpg";
// import cb1 from "./../assets/images/pigeonLandingPage/cb1.jpg";
// import cb2 from "./../assets/images/pigeonLandingPage/cb2.jpg";
// import cb3 from "./../assets/images/pigeonLandingPage/cb3.jpg";
// import element_01 from "./../assets/images/pigeonLandingPage/element_01.png";
// import element_02 from "./../assets/images/pigeonLandingPage/element_02.png";
// import fifthsection_bg from "./../assets/images/pigeonLandingPage/fifthsection_bg.png";
import { constantsAr, constantsEn } from "../utils/landingPageConstants";
class SampleNextArrow extends Component {
  render() {
    const { className, style, onClick } = this.props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
}

class SamplePrevArrow extends Component {
  render() {
    const { className, style, onClick } = this.props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
}

class Landing extends Component {
  productEnquiry = (url) => {
    if (url) {
      window.open(url);
    }
  };

  render() {
    // console.log(landingPageProducts);

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  const { global } = this.props;
  const constants = global.activeLanguage === "en" ? constantsEn : constantsAr;
    return (
      <div className="landing-main-class">
        <section class="__desktop-banner">
          <img src={global.activeLanguage === "en" ? bannerLarge : bannerLargeAr} alt="Banner" class="__negative-margin-top" />
        </section>
        <section class="__mobile-banner">
          <img src={global.activeLanguage === "en" ? bannerSmall : bannerSmallAr} alt="Banner" />
        </section>

        <section class="__section-padding-small __z-index-30">
          <div class="__text-center __max-width-4xl __padding-4 __z-index-30">
            <h1 class="__text-primary __text-2xl __font-bold __margin-bottom-4 __z-index-30">
            {constants?.dearMoms}
            </h1>
            <p class="__text-gray __text-base __font-thin __text-sm __margin-bottom-4 __z-index-30">
              {constants?.motherhoodMessage}
            </p>
            <p class="__text-gray __text-base __font-thin __text-sm __z-index-30">
              {constants?.introducingBottle}
            </p>
          </div>
        </section>

        <section class="__landing-bg-primary __landing-py-8">
          <h1 class="__landing-text-heading __landing-mb-4 __landing-text-center">
            {constants?.naturalSwitch}
          </h1>
          <div class="__landing-container">
            <div class="__landing-grid">
              <div class="__landing-card">
                <div class="__landing-img-wrapper">
                  <img
                    src={latch_on_line}
                    alt="Image of a baby bottle with latch-on line"
                    class="__landing-img"
                  />
                </div>
                <p class="__landing-text">
                  {constants?.latchDesc}
                </p>
                <div class="__landing-card-footer">
                  <div class="__landing-card-footer-bg">
                    <span class="__landing-card-footer-text">
                      {constants?.latch}
                    </span>
                  </div>
                </div>
              </div>
              <div class="__landing-card">
                <div class="__landing-img-wrapper">
                  <img
                    src={soft_and_flexible_nipple}
                    alt="Image of a baby bottle with latch-on line"
                    class="__landing-img"
                  />
                </div>
                <p class="__landing-text">
                 {constants?.softestDesc}
                </p>
                <div class="__landing-card-footer">
                  <div class="__landing-card-footer-bg">
                    <span class="__landing-card-footer-text">
                      {constants?.softFlexibleNipple}
                    </span>
                  </div>
                </div>
              </div>
              <div class="__landing-card">
                <div class="__landing-img-wrapper">
                  <img
                    src={anti_colic_system}
                    alt="Image of a baby bottle with latch-on line"
                    class="__landing-img"
                  />
                </div>
                <p class="__landing-text">
                  {constants?.antiColicSystemDescription}
                </p>
                <div class="__landing-card-footer">
                  <div class="__landing-card-footer-bg">
                    <span class="__landing-card-footer-text">
                      {constants?.antiColicSystem}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product-section">
          <div className="product-grid">
            <div className="product-header">
              <h1 className="product-header-title">
                <span className="product-header-subtitle">
                  {constants.explore}
                  <br className="product-header-break" />
                </span>
                {" "}{constants.exploreFullRange}
              </h1>
            </div>
            <div className="product-slider-container">
              <div className="productsSlider slider-container product-slider">
                <Slider {...settings}>
                  {landingPageProducts.map((product) => (
                    <div key={product.id} className="product-slide">
                      <div className="product-card">
                        <span className="product-size">{product.size}</span>
                        <img
                          src={product.image}
                          alt={product.alt}
                          className="product-image"
                        />
                        <h3 className="product-name">{global.activeLanguage == "en" ? product.name : product.nameAr}</h3>
                        <div className="product-button-container">
                          <button
                            className="product-button"
                            onClick={() => this.productEnquiry(product.url)}
                          >
                           {constants?.buyNow}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section class="__support-section">
          <div class="__support-container">
            <h2 class="__support-heading">
              {constants?.supportBreastfeeding}
            </h2>
            <div class="__support-hidden-sm">
              <img src={global.activeLanguage == "en" ? element_04 : element_04_ar} alt="Created to Support Breastfeeding" />
            </div>
            <div class="__support-hidden-lg">
              <img
                src={global.activeLanguage == "en" ? fifth_section__img : fifth_section_ar_img}
                alt="Created to Support Breastfeeding"
              />
            </div>
          </div>
        </section>

        <section class="__baby-section">
          <div class="__baby-hidden-sm">
            <img src={element_03} alt="element_03" />
          </div>
          <div class="__baby-hidden-lg">
            <img src={sixth_section_img} alt="element_03" />
          </div>
          <div class="__baby-absolute __baby-bottom-40 sm:__baby-bottom-10 __baby-opacity-95">
            <div class="__baby-content">
              <p class="__baby-text">
                {constants?.description1} <br class="__baby-hidden-sm" />
                {constants?.description2}
              </p>
            </div>
          </div>
        </section>

        <section class="__research-section">
          <div class="__research-bg"></div>
          <h1 class="__research-heading">
            {constants?.researchTitle}
          </h1>
          <div class="__research-cards-container">
            <div class="__research-card">
              <div class="__research-card-img-wrapper">
                <img src={c1} alt="Latching On" />
              </div>
              <div class="__research-card-content-wrapper">
                <div class="__research-card-content">
                  <h2 class="__research-card-title">
                    {constants?.latchingOn}
                  </h2>
                  <p class="__research-card-text">
                   {constants?.latchingOnDescription}
                  </p>
                </div>
              </div>
            </div>
            <div class="__research-card">
              <div class="__research-card-img-wrapper">
                <img src={c2} alt="Sucking" />
              </div>
              <div class="__research-card-content-wrapper">
                <div class="__research-card-content">
                  <h2 class="__research-card-title">
                    {constants?.sucking}
                  </h2>
                  <p class="__research-card-text">
                   {constants?.suckingDescription}
                  </p>
                </div>
              </div>
            </div>
            <div class="__research-card">
              <div class="__research-card-img-wrapper">
                <img src={c3} alt="Swallowing" />
              </div>
              <div class="__research-card-content-wrapper">
                <div class="__research-card-content">
                  <h2 class="__research-card-title">
                    {constants?.swallowing}
                  </h2>
                  <p class="__research-card-text">
                    {constants?.swallowingDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="__study-section">
          <div class="__study-container">
            <div class="__study-video-wrapper">
              <iframe
                class="__study-iframe"
                src="https://www.youtube.com/embed/DvitAj8aTd0?si=WkW9U_EKQPrcOYg4"
                title="Pigeon SofTouch™ - New and improved"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div class="__study-text-wrapper">
              <h2 class="__study-heading">
                {constants?.studyTitle}
              </h2>
              <p class="__study-text">
               {constants?.studyDescription}
              </p>
            </div>
          </div>
        </section>

        <section class="__learn-container">
          <h1 class="__learn-heading">
           {constants?.learnMore}
          </h1>
          <div class="__learn-grid">
            <div class="__learn-video-wrapper">
              <iframe
                class="__learn-iframe"
                src="https://www.youtube.com/embed/1oPr2PkVVms?si=VU1L5SO9U4bSVbPQ"
                title="Pigeon SofTouch™ - New and improved"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div class="__learn-video-wrapper">
              <iframe
                class="__learn-iframe"
                src="https://www.youtube.com/embed/_a8lJko9emA?si=3aJ13aPiOzOIZWB4"
                title="Pigeon SofTouch™ - Bringing smiles to every family"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global: state.globalReducer,
  };
};
export default connect(mapStateToProps)(Landing);
